<template>
  <v-container fill-height fluid class="lighten-5 text-center ma-0 pa-0">
    <v-layout align-center justify-center>
      <!-- Loader  -->
      <v-row align="center" justify="center" v-if="loading_dialog">
        <v-col>
          <div class="text-center">
            <v-progress-circular indeterminate color="primary" :width="2" :size="25"></v-progress-circular>
          </div>
          <p caption class="mt-2">Loading... Please wait</p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" v-else>
        <v-col start>
          <!-- only touch if you know what you're doing -->
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12">
          <v-card class="mx-auto spacing-playground pa-2 align-center" outlined>
            <div class="d-flex justify-center mt-5" @click="goToHomePage">
              <v-img lazy-src="img/logo-128.png" max-height="178" max-width="178" src="img/logo-128.png"></v-img>
            </div>

            <div class="d-flex justify-center pa-5">
              <v-img
                :src="imageSrc"
                :lazy-src="imageLazySrc"
                aspect-ratio="1"
                max-width="300"
                max-height="300"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>

            <v-divider class="mt-2"></v-divider>

            <div class="d-flex justify-center mt-5">
              <span class="text-caption grey--text">
                {{ this.currentYear }} &copy; <a @click="goToHomePage" class="text-decoration-none">Bluezone</a>.
              </span>
            </div>
          </v-card>
        </v-col>
        <v-col end>
          <!-- only touch if you know what you're doing -->
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import ConfigService from '../../services/config.service';

export default {
  data: () => ({
    showPassword: false,
    resend_otp: false,
    national_number: "KE",
    otp_verified: true,
    verification_otp: null,
    loading: false,
    toggle_exclusive: undefined,
    loading_dialog: true,
    pageReady: false,
    imageSrc: 'https://picsum.photos/id/11/10/6',
    imageLazySrc: 'https://picsum.photos/id/11/500/300',
    user: {
      username: " ",
    },
    phone: null,
    valid: true,
    remember: 0,
    timeout: 2000,
    otpRules: [
      (v) => !!v || "OTP is required",
      (v) => (v && v.length == 5) || "The OTP has 6 characters"
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Email or phone (+2547...) is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    currentYear: new Date().getFullYear(),
    config: {},
  }),
  name: "QrCode",
  opts: {
    theme: {
      dark: false,
    },
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    computedLogo() {
      return this.config.logo_url;
    },
  },

  created() {
    localStorage.clear();
    this.systemConfig();
    this.getQrCode();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    gotToRegisterBasic() {
      this.$router.push("/register");
    },
    gotToRegisterFacilityManager() {
      this.$router.push("/register-facility-manager");
    },
    gotToRegisterOrganisation() {
      this.$router.push("/register-organisation");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    goToHomePage() {
      this.$router.push("/");
    },
    systemConfig() {
      setTimeout(() => {
        this.loading_dialog = false;
        this.pageReady = true;
      }, 1500);
      return;
    },
    getQrCode() {
      let query = this.$route.query?.payload ?? 'payload';
      ConfigService.getQrCode(query).then(
        (response) => {
          if (response.status == 200) {
            const urlCreator = window.URL || window.webkitURL;
            this.imageLazySrc = urlCreator.createObjectURL(response.data);
            this.imageSrc = urlCreator.createObjectURL(response.data);
          }
        },
        async (error) => {
          let errorObj = await error.response.data.text();
          let errorMessage = JSON.parse(errorObj).message;
          this.$store.dispatch("alert/error", errorMessage);
        }
      );
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>