import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Auth/Login.vue';
import Register from '../views/Pages/Register.vue';
import PrivacyPolicy from '../views/Auth/PrivacyPolicy.vue';
import QrCode from '../views/Auth/QrCode.vue';
import RegisterOrganisation from '../views/Pages/RegisterOrganisation.vue';
import RegisterFacilityManager from '../views/Pages/RegisterFacilityManager.vue';
import ResetPassword from '../views/Pages/ResetPassword.vue';
import ForgotPassword from '../views/Pages/ForgotPassword.vue';
import NotFound from '../views/Pages/NotFound.vue';
import ResetPassWithOTP from "@/views/Pages/ResetPassWithOTP.vue";
import ContactUs from '../views/Auth/ContactUs.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/qr-code',
    name: 'QrCode',
    component: QrCode
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/register-organisation',
    name: 'RegisterOrganisation',
    component: RegisterOrganisation
  },
  {
    path: '/register-facility-manager',
    name: 'RegisterFacilityManager',
    component: RegisterFacilityManager
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/reset-password-with-otp',
    name: 'ResetPassWithOTP',
    component: ResetPassWithOTP
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },

  {
    path: '/',
    component: () => import(/* webpackChunkName: "main" */'../views/Layout/Main.vue'),
    children: [
      {
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */'../views/Dashboard/Dashboard.vue'),
      },
      {
        path: '/organisations',
        component: () => import(/* webpackChunkName: "organization" */'../views/Organization/Organisations.vue'),
      },
      {
        path: '/reports',
        component: () => import(/* webpackChunkName: "reports" */'../views/Pages/Reports.vue'),
      },

      {
        path: '/view_reports',
        component: () => import(/* webpackChunkName: "view-reports" */'../views/Pages/ViewReport/ViewReport.vue'),
        name: "ViewReport",
        props: true
      },

      {
        path: '/facility',
        component: () => import(/* webpackChunkName: "facility" */'../views/Facility/Facility.vue'),
      },
      {
        path: '/calendar',
        component: () => import(/* webpackChunkName: "calender" */'../views/Pages/Calendar.vue'),
        props: true
      },
      {
        path: '/watchmen-schedule',
        component: () => import(/* webpackChunkName: "watchmen" */'../views/Schedule/Watchmen.vue'),
      },
      {
        path: '/complete-profile',
        component: () => import(/* webpackChunkName: "profile" */'../views/Profile/UserProfile.vue'),
      },
      {
        path: '/company-profile',
        component: () => import(/* webpackChunkName: "company-profile" */'../views/Settings/CompanyProfile.vue'),
      },
      {
        path: '/sms-logs',
        component: () => import(/* webpackChunkName: "company-profile" */'../views/SmsLogs/SmsLogs.vue'),
      },
      {
        path: '/system-configurations',
        component: () => import(/* webpackChunkName: "company-profile" */'../views/Settings/SystemConfigurations.vue'),
      },
      {
        path: '/404/:resource',
        name: '404Resource',
        component: NotFound,
        props: true
      }
    ],
    meta: { requiresAuth: true },
    redirect: '/dashboard'
  }

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.VUE_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('user')) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
