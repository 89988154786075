import Axios from './Axios';

class ReportsService {

  listAll(payload, watchmanId = null) {
    return Axios({
      method: "GET",
      url: "visitor_reports?search_term=" + payload.search_term + "&per_page=" + payload.options?.itemsPerPage + "&page=" + payload.options?.page + "&watchman_id=" + watchmanId + "&" + this.generateUrlParams(payload.filters),
    })
  }

  generateUrlParams(obj) {
    var str = "";

    for (var key in obj) {
      if (str != "") {
          str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }

    return str;
  }

  upcomingVisits(organization_id) {
    return Axios({
      method: "GET",
      url: "organization_upcoming_visits/" + organization_id
    });
  }

  pastVisits(organization_id) {
    return Axios({
      method: "GET",
      url: "organization_past_visits/" + organization_id
    });
  }

  smsLogs(organisation_id, payload) {
    return Axios({
      method: "GET",
      url: "sms-logs/" + organisation_id + '?page=' + payload.options?.page + '&per_page=' + payload.options?.itemsPerPage
    });
  }
}

export default new ReportsService()
