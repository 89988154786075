<template>
  <v-container fill-height fluid class="lighten-5 text-center ma-0 pa-0">
    <v-layout align-center justify-center>
      <!-- Loader  -->
      <v-row align="center" justify="center" v-if="loading_dialog">
        <v-col>
          <div class="text-center">
            <v-progress-circular indeterminate color="primary" :width="2" :size="25"></v-progress-circular>
          </div>
          <p caption class="mt-2">Loading... Please wait</p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" v-else>
        <v-col start>
          <!-- only touch if you know what you're doing -->
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12">
          <v-card class="mx-auto spacing-playground pa-2 align-center" outlined>
            <div class="d-flex justify-center mt-5" @click="goToHomePage">
              <v-img lazy-src="img/logo-128.png" max-height="178" max-width="178" src="img/logo-128.png"></v-img>
            </div>

            <div>
              <v-card-text class="text-left">
                <div>
                  <strong>
                    <span style="font-size: 26px;">
                      <span data-custom-class="title">
                        <bdt class="block-component"></bdt>
                        <bdt class="question">PRIVACY POLICY</bdt>
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span>
                    </span>
                  </strong>
                </div>
                <div><br></div>
                <div>
                  <span style="color: rgb(127, 127, 127);">
                    <strong>
                      <span style="font-size: 15px;">
                        <span data-custom-class="subtitle">
                          Last updated
                          <bdt class="question">August 09, 2024</bdt>
                        </span>
                      </span>
                    </strong>
                  </span>
                </div>
                <div><br></div>
                <div><br></div>
                <div><br></div>
                <div style="line-height: 1.5;">
                  <span style="color: rgb(127, 127, 127);">
                    <span style="color: rgb(89, 89, 89); font-size: 15px;">
                      <span data-custom-class="body_text">
                        This privacy notice for
                        <bdt class="question">
                          Blue Ion Limited
                          <bdt class="block-component"></bdt>
                        </bdt>
                        (
                        <bdt class="block-component"></bdt>
                        '<strong>we</strong>', '<strong>us</strong>', or '<strong>our</strong>'
                        <bdt class="else-block"></bdt>
                      </span>
                      <span data-custom-class="body_text">
                        ), describes how and why we might collect, store, use, and/or share (
                        <bdt class="block-component"></bdt>
                        '<strong>process</strong>'
                        <bdt class="else-block"></bdt>
                        ) your information when you use our services (
                        <bdt class="block-component"></bdt>
                        '<strong>Services</strong>'
                        <bdt class="else-block"></bdt>
                        ), such as when you:
                      </span>
                    </span>
                  </span>
                  <span style="font-size: 15px;">
                    <span style="color: rgb(127, 127, 127);">
                      <span data-custom-class="body_text">
                        <span style="color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div><br></div>
                <ul>
                  <li data-custom-class="body_text" style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          Visit our website
                          <bdt class="block-component"></bdt>
                          at
                          <span style="color: rgb(0, 58, 250);">
                            <bdt class="question"><a href="https://cbk.egovernance.io/#/"
                                class="info--text text-decoration-none" target="_blank">cbk.egovernance.io</a></bdt>
                          </span>
                          <span style="font-size: 15px;">
                            <span style="color: rgb(89, 89, 89);">
                              <span data-custom-class="body_text">
                                <span style="font-size: 15px;">
                                  <span style="color: rgb(89, 89, 89);">
                                    <bdt class="statement-end-if-in-editor">, or any website of ours that links to this
                                      privacy notice</bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text" style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          Engage with us in other related ways, including any sales, marketing, or events
                          <span style="font-size: 15px;">
                            <span style="color: rgb(89, 89, 89);">
                              <span data-custom-class="body_text">
                                <span style="font-size: 15px;">
                                  <span style="color: rgb(89, 89, 89);">
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span style="color: rgb(127, 127, 127);">
                      <span data-custom-class="body_text">
                        <strong>Questions or concerns? </strong>Reading this privacy notice will help you understand
                        your privacy rights and choices. If you do not agree with our policies and practices, please do
                        not use our Services.
                        <bdt class="block-component"></bdt>
                        If you still have any questions or concerns, please contact us at
                        <bdt class="question"><a href="mailto:info@theblueion.com"
                            class="info--text text-decoration-none" target="_blank">info@theblueion.com</a></bdt>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <strong>
                    <span style="font-size: 15px;">
                      <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
                    </span>
                  </strong>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text"><strong><em>This summary provides key points from our privacy
                          notice, but you can find out more details about any of these topics by clicking the link
                          following each key point or by using our </em></strong>
                    </span>
                  </span>
                  <a data-custom-class="link" href="#toc"><span style="color: rgb(0, 58, 250); font-size: 15px;">
                      <span data-custom-class="body_text"><strong><em>table of contents</em></strong></span></span>
                  </a>
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text"><strong><em> below to find the section you are looking
                          for.</em></strong></span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text"><strong>What personal information do we process?</strong> When
                      you
                      visit, use, or navigate our Services, we may process personal information depending on how you
                      interact with us and the Services, the choices you make, and the products and features you use.
                      Learn more about
                    </span>
                  </span>
                  <a data-custom-class="link" href="#personalinfo">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;">
                      <span data-custom-class="body_text">personal information you disclose to us</span>
                    </span>
                  </a><span data-custom-class="body_text">.</span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <strong>Do we process any sensitive personal information?</strong>
                      <bdt class="block-component"></bdt>
                      We may process sensitive personal information when necessary with your consent or as otherwise
                      permitted by applicable law. Learn more about
                    </span>
                  </span>
                  <a data-custom-class="link" href="#sensitiveinfo">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;"><span data-custom-class="body_text">sensitive
                        information we process</span></span></a><span data-custom-class="body_text">.</span>
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <bdt class="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <strong>Do we collect any information from third parties?</strong>
                      <bdt class="block-component"></bdt>
                      We do not collect any information from third parties.
                      <bdt class="else-block"></bdt>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <strong>How do we process your information?</strong> We process your
                      information to provide, improve, and administer our Services, communicate with you, for security
                      and fraud prevention, and to comply with law. We may also process your information for other
                      purposes with your consent. We process your information only when we have a valid legal reason to
                      do so. Learn more about
                    </span>
                  </span>
                  <a data-custom-class="link" href="#infouse">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;">
                      <span data-custom-class="body_text">how we process your information</span>
                    </span>
                  </a>
                  <span data-custom-class="body_text">.</span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <strong>
                        In what situations and with which
                        <bdt class="block-component"></bdt>
                        parties do we share personal information?
                      </strong>
                      We may share information in specific situations and with specific
                      <bdt class="block-component"></bdt>
                      third parties. Learn more about
                    </span>
                  </span>
                  <a data-custom-class="link" href="#whoshare">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;">
                      <span data-custom-class="body_text">when and with whom we share your personal information</span>
                    </span>
                  </a>
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      .
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <strong>How do we keep your information safe?</strong> We have
                      <bdt class="block-component"></bdt>
                      organisational
                      <bdt class="else-block"></bdt>
                      and technical processes and procedures in place to protect your personal information. However, no
                      electronic transmission over the internet or information storage technology can be guaranteed to
                      be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
                      <bdt class="block-component"></bdt>
                      unauthorised
                      <bdt class="else-block"></bdt>
                      third parties will not be able to defeat our security and improperly collect, access, steal, or
                      modify your information. Learn more about
                    </span>
                  </span>
                  <a data-custom-class="link" href="#infosafe">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;">
                      <span data-custom-class="body_text">how we keep your information safe</span>
                    </span>
                  </a>
                  <span data-custom-class="body_text">.</span>
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <bdt class="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text"><strong>What are your rights?</strong> Depending on where you
                      are
                      located geographically, the applicable privacy law may mean you have certain rights regarding your
                      personal information. Learn more about
                    </span>
                  </span>
                  <a data-custom-class="link" href="#privacyrights">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;">
                      <span data-custom-class="body_text">your privacy rights</span>
                    </span>
                  </a>
                  <span data-custom-class="body_text">.</span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by
                      <bdt class="block-component"></bdt>
                      <bdt class="else-block"></bdt>
                      contacting us. We will consider and act upon any request in accordance with applicable
                      data protection laws.
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">Want to learn more about what we do with any information we
                      collect?</span>
                  </span>
                  <a data-custom-class="link" href="#toc">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;">
                      <span data-custom-class="body_text"> Review the privacy notice in full</span>
                    </span>
                  </a>
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">.</span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;"><br></div>
                <div id="toc" style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span style="color: rgb(127, 127, 127);">
                      <span style="color: rgb(0, 0, 0);">
                        <strong><span data-custom-class="heading_1">TABLE OF CONTENTS</span></strong>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <a data-custom-class="link" href="#infocollect">
                      <span style="color: rgb(0, 58, 250);">1. WHAT INFORMATION DO WE COLLECT?</span>
                    </a>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <a data-custom-class="link" href="#infouse">
                      <span style="color: rgb(0, 58, 250);">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                        <bdt class="block-component"></bdt>
                      </span>
                    </a>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span style="color: rgb(0, 58, 250);">
                      <a data-custom-class="link" href="#whoshare">
                        <span style="color: rgb(0, 58, 250);">
                          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                          <bdt class="block-component"></bdt>
                        </span>
                      </a>
                    </span>
                    <span data-custom-class="body_text">
                      <bdt class="block-component"></bdt>
                      <span style="color: rgb(127, 127, 127);">
                        <span style="color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <span style="color: rgb(89, 89, 89);">
                              <bdt class="block-component"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <a data-custom-class="link" href="#cookies">
                      <span style="color: rgb(0, 58, 250);">
                        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                      </span>
                    </a>
                    <span style="color: rgb(127, 127, 127);">
                      <span style="color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="color: rgb(89, 89, 89);">
                            <bdt class="statement-end-if-in-editor"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                    <bdt class="block-component"></bdt>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span style="color: rgb(127, 127, 127);">
                      <span style="color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="color: rgb(89, 89, 89);">
                            <span style="color: rgb(89, 89, 89);">
                              <span style="color: rgb(89, 89, 89);">
                                <bdt class="block-component"></bdt>
                              </span>
                            </span>
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <a data-custom-class="link" href="#inforetain">
                      <span style="color: rgb(0, 58, 250);">
                        5. HOW LONG DO WE KEEP YOUR INFORMATION?
                      </span>
                    </a>
                    <span style="color: rgb(127, 127, 127);">
                      <span style="color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="color: rgb(89, 89, 89);">
                            <span style="color: rgb(89, 89, 89);">
                              <bdt class="block-component"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <a data-custom-class="link" href="#infosafe">
                      <span style="color: rgb(0, 58, 250);">
                        6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                      </span>
                    </a>
                    <span style="color: rgb(127, 127, 127);">
                      <span style="color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="color: rgb(89, 89, 89);">
                            <bdt class="statement-end-if-in-editor"></bdt>
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <a data-custom-class="link" href="#infominors">
                      <span style="color: rgb(0, 58, 250);">
                        7. DO WE COLLECT INFORMATION FROM MINORS?
                      </span>
                    </a>
                    <span style="color: rgb(127, 127, 127);">
                      <span style="color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="color: rgb(89, 89, 89);">
                            <bdt class="statement-end-if-in-editor"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <a data-custom-class="link" href="#privacyrights">
                      <span style="color: rgb(0, 58, 250);">
                        8. WHAT ARE YOUR PRIVACY RIGHTS?
                      </span>
                    </a>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <a data-custom-class="link" href="#DNT">
                      <span style="color: rgb(0, 58, 250);">
                        9. CONTROLS FOR DO-NOT-TRACK FEATURES
                        <bdt class="block-component"></bdt>
                      </span>
                    </a>
                    <bdt class="block-component">
                      <span style="font-size: 15px;">
                        <span data-custom-class="body_text"></span>
                      </span>
                    </bdt>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <bdt class="block-component">
                    <span style="font-size: 15px;"></span>
                  </bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <a data-custom-class="link" href="#policyupdates">
                      <span style="color: rgb(0, 58, 250);">
                        10. DO WE MAKE UPDATES TO THIS NOTICE?
                      </span>
                    </a>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <a data-custom-class="link" href="#contact">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;">
                      11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </span>
                  </a>
                </div>
                <div style="line-height: 1.5;">
                  <a data-custom-class="link" href="#request">
                    <span style="color: rgb(0, 58, 250);">
                      12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </span>
                  </a>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;"><br></div>
                <div id="infocollect" style="line-height: 1.5;">
                  <span style="color: rgb(0, 0, 0);">
                    <span style="color: rgb(0, 0, 0); font-size: 15px;">
                      <span style="font-size: 15px; color: rgb(0, 0, 0);">
                        <span style="font-size: 15px; color: rgb(0, 0, 0);">
                          <span id="control" style="color: rgb(0, 0, 0);">
                            <strong>
                              <span data-custom-class="heading_1">
                                1. WHAT INFORMATION DO WE COLLECT?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div id="personalinfo" style="line-height: 1.5;">
                  <span data-custom-class="heading_2" style="color: rgb(0, 0, 0);">
                    <span style="font-size: 15px;"><strong>Personal information you disclose to us</strong></span>
                  </span>
                </div>
                <div>
                  <div><br></div>
                  <div style="line-height: 1.5;">
                    <span style="color: rgb(127, 127, 127);">
                      <span style="color: rgb(89, 89, 89); font-size: 15px;">
                        <span data-custom-class="body_text">
                          <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span style="font-size: 15px; color: rgb(89, 89, 89);">
                              <span data-custom-class="body_text">
                                <strong><em>In Short:</em></strong>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span data-custom-class="body_text">
                          <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span style="font-size: 15px; color: rgb(89, 89, 89);">
                              <span data-custom-class="body_text">
                                <strong><em> </em></strong><em>We collect personal information that you provide to
                                  us.</em>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">We collect personal information that you voluntarily provide
                        to us when you
                        <span style="font-size: 15px;">
                          <bdt class="block-component"></bdt>
                        </span>register on the Services,
                      </span>
                      <span style="font-size: 15px;">
                        <span data-custom-class="body_text">
                          <span style="font-size: 15px;">
                            <bdt class="statement-end-if-in-editor"></bdt>
                          </span>
                        </span>
                        <span data-custom-class="body_text">express an interest in obtaining information
                          about us or our products and Services, when you participate in activities on the Services, or
                          otherwise when you contact us.
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <span style="font-size: 15px;">
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <strong>Personal Information Provided by You.</strong> The
                        personal information that we collect depends on the context of your interactions with us and the
                        Services, the choices you make, and the products and features you use. The personal information
                        we collect may include the following:
                        <span style="font-size: 15px;">
                          <span data-custom-class="body_text">
                            <bdt class="forloop-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <span style="font-size: 15px;">
                              <span data-custom-class="body_text">
                                <bdt class="question">names</bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="font-size: 15px;">
                            <span data-custom-class="body_text">
                              <bdt class="forloop-component"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <span style="font-size: 15px;">
                              <span data-custom-class="body_text">
                                <bdt class="question">phone numbers</bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="font-size: 15px;">
                            <span data-custom-class="body_text">
                              <bdt class="forloop-component"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <span style="font-size: 15px;">
                              <span data-custom-class="body_text">
                                <bdt class="question">email addresses</bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="font-size: 15px;">
                            <span data-custom-class="body_text">
                              <bdt class="forloop-component"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <span style="font-size: 15px;">
                              <span data-custom-class="body_text">
                                <bdt class="question">usernames</bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="font-size: 15px;">
                            <span data-custom-class="body_text">
                              <bdt class="forloop-component"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <span style="font-size: 15px;">
                              <span data-custom-class="body_text">
                                <bdt class="question">passwords</bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <span style="font-size: 15px;">
                            <span data-custom-class="body_text">
                              <bdt class="forloop-component"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <span style="font-size: 15px;">
                              <span data-custom-class="body_text">
                                <bdt class="question">contact or authentication data</bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <span style="font-size: 15px;">
                          <span data-custom-class="body_text">
                            <bdt class="forloop-component"></bdt>
                          </span>
                          <span data-custom-class="body_text">
                            <bdt class="statement-end-if-in-editor"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div><br></div>
                <div id="sensitiveinfo" style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <strong>Sensitive Information.</strong>
                      <bdt class="block-component"></bdt> When necessary, with your consent or as otherwise permitted by
                      applicable law, we process the following categories of sensitive information:
                      <bdt class="forloop-component"></bdt>
                    </span>
                  </span>
                </div>
                <ul>
                  <li data-custom-class="body_text" style="line-height: 1.5;">
                    <span style="font-size: 15px;">
                      <span data-custom-class="body_text">
                        <bdt class="question">social security numbers or other government identifiers</bdt>
                      </span>
                    </span>
                  </li>
                </ul>
                <div><br></div>
                <div>
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <bdt class="forloop-component"></bdt>
                    </span>
                    <span data-custom-class="body_text">
                      <bdt class="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span data-custom-class="body_text">
                      <span style="font-size: 15px;">
                        <span data-custom-class="body_text">
                          <bdt class="block-component">
                            <bdt class="block-component"></bdt>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt class="block-component"></bdt>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">All personal
                        information that you provide to us must be true, complete, and accurate, and you must notify us
                        of any changes to such personal information.
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                  <bdt class="block-component">
                    <span style="font-size: 15px;"></span>
                  </bdt>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <strong>
                      <span data-custom-class="heading_2">Google API</span>
                    </strong>
                  </span>
                </div>
                <div style="line-height: 1.5;"><span style="font-size: 15px;"><br></span></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      Our use of information received from Google APIs will adhere to
                    </span>
                  </span>
                  <a data-custom-class="link" href="https://developers.google.com/terms/api-services-user-data-policy"
                    rel="noopener noreferrer" target="_blank">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;">
                      <span data-custom-class="body_text">Google API Services User Data Policy</span></span></a><span
                    style="font-size: 15px;"><span data-custom-class="body_text">, including the </span></span><a
                    data-custom-class="link"
                    href="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
                    rel="noopener noreferrer" target="_blank"><span
                      style="color: rgb(0, 58, 250); font-size: 15px;"><span data-custom-class="body_text">Limited Use
                        requirements</span></span></a><span style="font-size: 15px;"><span
                      data-custom-class="body_text">.</span><br>
                  </span>
                </div>
                <div><span style="font-size: 15px;"><br></span></div>
                <div style="line-height: 1.5;">
                  <bdt class="statement-end-if-in-editor">
                    <span style="font-size: 15px;"></span>
                  </bdt>
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <span style="color: rgb(89, 89, 89); font-size: 15px;">
                          <span data-custom-class="body_text">
                            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                              <span data-custom-class="body_text">
                                <bdt class="statement-end-if-in-editor">
                                  <bdt class="block-component"></bdt>
                                </bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div id="infouse" style="line-height: 1.5;">
                  <span style="color: rgb(127, 127, 127);">
                    <span style="color: rgb(89, 89, 89); font-size: 15px;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span id="control" style="color: rgb(0, 0, 0);">
                            <strong><span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR
                                INFORMATION?</span></strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div>
                  <div style="line-height: 1.5;"><br></div>
                  <div style="line-height: 1.5;">
                    <span style="color: rgb(127, 127, 127);">
                      <span style="color: rgb(89, 89, 89); font-size: 15px;">
                        <span data-custom-class="body_text">
                          <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span style="font-size: 15px; color: rgb(89, 89, 89);">
                              <span data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We process
                                  your
                                  information to provide, improve, and administer our Services, communicate with you,
                                  for security and fraud prevention, and to comply with law. We may also process your
                                  information for other purposes with your
                                  consent.</em>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <strong>
                          We process your personal information for a variety of reasons, depending on how you interact
                          with
                          our Services, including:
                        </strong>
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                </div>
                <ul>
                  <li data-custom-class="body_text" style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <strong>To facilitate account creation and authentication and otherwise manage user accounts.
                          </strong>We may process your information so you can create and log in to your account, as well
                          as keep your account in working order.
                          <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span style="font-size: 15px; color: rgb(89, 89, 89);">
                              <span data-custom-class="body_text">
                                <span style="font-size: 15px;">
                                  <span style="color: rgb(89, 89, 89);">
                                    <span data-custom-class="body_text">
                                      <span style="font-size: 15px;">
                                        <span style="color: rgb(89, 89, 89);">
                                          <span data-custom-class="body_text">
                                            <bdt class="statement-end-if-in-editor"></bdt>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                </div>
                <ul>
                  <li data-custom-class="body_text" style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <strong>To deliver and facilitate delivery of services to the user. </strong>We may process
                          your information to provide you with the requested service.
                          <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span style="font-size: 15px; color: rgb(89, 89, 89);">
                              <span data-custom-class="body_text">
                                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                    <span data-custom-class="body_text">
                                      <span style="font-size: 15px;">
                                        <span style="color: rgb(89, 89, 89);">
                                          <span data-custom-class="body_text">
                                            <span style="font-size: 15px;">
                                              <span style="color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">
                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                  <div style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <strong>To respond to user inquiries/offer support to users. </strong>We may process your
                            information to respond to your inquiries and solve any potential issues you might have with
                            the requested service.
                            <span style="font-size: 15px; color: rgb(89, 89, 89);">
                              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                <span data-custom-class="body_text">
                                  <span style="font-size: 15px;">
                                    <span style="color: rgb(89, 89, 89);">
                                      <span data-custom-class="body_text">
                                        <span style="font-size: 15px;">
                                          <span style="color: rgb(89, 89, 89);">
                                            <span data-custom-class="body_text">
                                              <bdt class="statement-end-if-in-editor"></bdt>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style="line-height: 1.5;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                    <div style="line-height: 1.5;">
                      <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                    </div>
                    <ul>
                      <li data-custom-class="body_text" style="line-height: 1.5;">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                              <strong>To send administrative information to you. </strong>We may process your
                              information to send you details about our products and services, changes to our terms and
                              policies, and other similar information.
                              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                  <span data-custom-class="body_text">
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div style="line-height: 1.5;">
                      <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                      <div style="line-height: 1.5;">
                        <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                        <div style="line-height: 1.5;">
                          <bdt class="block-component">
                            <span style="font-size: 15px;">
                              <span data-custom-class="body_text"></span>
                            </span>
                          </bdt>
                          <p style="font-size: 15px; line-height: 1.5;">
                            <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5;">
                            <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5;">
                            <bdt class="block-component"></bdt>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5;">
                            <bdt class="block-component"></bdt>
                          </p>
                          <div style="line-height: 1.5;">
                            <bdt class="block-component">
                              <span style="font-size: 15px;">
                                <span data-custom-class="body_text"></span>
                              </span>
                            </bdt>
                            <div style="line-height: 1.5;">
                              <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                              <div style="line-height: 1.5;">
                                <bdt class="block-component">
                                  <span style="font-size: 15px;"></span>
                                </bdt>
                                <div style="line-height: 1.5;">
                                  <span style="font-size: 15px;">
                                    <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                                  </span>
                                  <div style="line-height: 1.5;">
                                    <bdt class="block-component">
                                      <span style="font-size: 15px;">
                                        <span data-custom-class="body_text"></span>
                                      </span>
                                    </bdt>
                                    <div style="line-height: 1.5;">
                                      <bdt class="block-component">
                                        <span style="font-size: 15px;">
                                          <span data-custom-class="body_text"></span>
                                        </span>
                                      </bdt>
                                      <div style="line-height: 1.5;">
                                        <bdt class="block-component">
                                          <span style="font-size: 15px;">
                                            <span data-custom-class="body_text"></span>
                                          </span>
                                        </bdt>
                                        <div style="line-height: 1.5;">
                                          <bdt class="block-component">
                                            <span style="font-size: 15px;">
                                              <span data-custom-class="body_text"></span>
                                            </span>
                                          </bdt>
                                          <div style="line-height: 1.5;">
                                            <bdt class="block-component">
                                              <span style="font-size: 15px;">
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                            <div style="line-height: 1.5;">
                                              <bdt class="block-component">
                                                <span style="font-size: 15px;">
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </bdt>
                                              <div style="line-height: 1.5;">
                                                <bdt class="block-component">
                                                  <span style="font-size: 15px;">
                                                    <span data-custom-class="body_text"></span>
                                                  </span>
                                                </bdt>
                                                <div style="line-height: 1.5;">
                                                  <bdt class="block-component">
                                                    <span style="font-size: 15px;">
                                                      <span data-custom-class="body_text"></span>
                                                    </span>
                                                  </bdt>
                                                  <div style="line-height: 1.5;">
                                                    <bdt class="block-component">
                                                      <span style="font-size: 15px;">
                                                        <span data-custom-class="body_text"></span>
                                                      </span>
                                                    </bdt>
                                                    <div style="line-height: 1.5;">
                                                      <bdt class="block-component">
                                                        <span style="font-size: 15px;">
                                                          <span data-custom-class="body_text"></span>
                                                        </span>
                                                      </bdt>
                                                      <div style="line-height: 1.5;">
                                                        <bdt class="block-component">
                                                          <span style="font-size: 15px;">
                                                            <span data-custom-class="body_text"></span>
                                                          </span>
                                                        </bdt>
                                                        <div style="line-height: 1.5;">
                                                          <bdt class="block-component">
                                                            <span style="font-size: 15px;">
                                                              <span data-custom-class="body_text"></span>
                                                            </span>
                                                          </bdt>
                                                          <bdt class="block-component">
                                                            <span style="font-size: 15px;">
                                                              <span data-custom-class="body_text"></span>
                                                            </span>
                                                          </bdt>
                                                          <bdt class="block-component">
                                                            <span style="font-size: 15px;">
                                                              <span data-custom-class="body_text"></span>
                                                            </span>
                                                          </bdt>
                                                          <bdt class="block-component">
                                                            <span style="font-size: 15px;">
                                                              <span data-custom-class="body_text"></span>
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div id="whoshare" style="line-height: 1.5;">
                                                          <span style="color: rgb(127, 127, 127);">
                                                            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                                                              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                  <span id="control" style="color: rgb(0, 0, 0);">
                                                                    <strong>
                                                                      <span data-custom-class="heading_1">
                                                                        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                                                                        INFORMATION?
                                                                      </span>
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div style="line-height: 1.5;">
                                                          <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                            <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                              <span data-custom-class="body_text">
                                                                <strong><em>In Short:</em></strong> <em> We may share
                                                                  information
                                                                  in specific situations described in this section
                                                                  and/or with the following
                                                                  <bdt class="block-component"></bdt> third parties.
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div style="line-height: 1.5;">
                                                          <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                            <span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                              <span data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div style="line-height: 1.5;">
                                                          <span style="font-size: 15px;">
                                                            <span data-custom-class="body_text">We <bdt
                                                                class="block-component"></bdt>may need to share your
                                                              personal information in the following
                                                              situations:
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li data-custom-class="body_text" style="line-height: 1.5;">
                                                            <span style="font-size: 15px;">
                                                              <span data-custom-class="body_text">
                                                                <strong>Business Transfers.</strong> We may share or
                                                                transfer your
                                                                information in connection with, or during negotiations
                                                                of, any merger, sale of company assets, financing, or
                                                                acquisition of all or a portion of our business to
                                                                another company.
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div style="line-height: 1.5;">
                                                          <span style="font-size: 15px;">
                                                            <span data-custom-class="body_text">
                                                              <bdt class="block-component"></bdt>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li data-custom-class="body_text" style="line-height: 1.5;">
                                                            <span style="font-size: 15px;">
                                                              <span data-custom-class="body_text">
                                                                <strong>When we use Google Maps Platform APIs.</strong>
                                                                We may share your
                                                                information with certain Google Maps Platform APIs (e.g.
                                                                <bdt class="block-component"></bdt> Google Maps API,
                                                                Places API).
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>
                                                                <bdt class="block-component"></bdt> We obtain and store
                                                                on your device (<bdt class="block-component"></bdt>
                                                                'cache'<bdt class="else-block"></bdt>) your location
                                                                <bdt class="block-component"></bdt>. You may revoke your
                                                                consent anytime by contacting us at the contact details
                                                                provided at the end of this document.<bdt
                                                                  class="statement-end-if-in-editor"></bdt>
                                                                <bdt class="block-component"></bdt>
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div style="line-height: 1.5;">
                                                          <span style="font-size: 15px;">
                                                            <bdt class="block-component">
                                                              <span data-custom-class="body_text"></span>
                                                            </bdt>
                                                          </span>
                                                          <div style="line-height: 1.5;">
                                                            <bdt class="block-component">
                                                              <span style="font-size: 15px;">
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </bdt>
                                                            <div style="line-height: 1.5;">
                                                              <bdt class="block-component">
                                                                <span style="font-size: 15px;">
                                                                  <span data-custom-class="body_text"></span>
                                                                </span>
                                                              </bdt>
                                                              <div style="line-height: 1.5;">
                                                                <bdt class="block-component">
                                                                  <span style="font-size: 15px;">
                                                                    <span data-custom-class="body_text"></span>
                                                                  </span>
                                                                </bdt>
                                                                <div style="line-height: 1.5;">
                                                                  <bdt class="block-component">
                                                                    <span style="font-size: 15px;">
                                                                      <span data-custom-class="body_text"></span>
                                                                    </span>
                                                                  </bdt>
                                                                  <span
                                                                    style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                    <span style="font-size: 15px;">
                                                                      <span style="color: rgb(89, 89, 89);">
                                                                        <span style="font-size: 15px;">
                                                                          <span style="color: rgb(89, 89, 89);">
                                                                            <bdt class="block-component">
                                                                              <span data-custom-class="heading_1">
                                                                                <bdt class="block-component"></bdt>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="cookies" style="line-height: 1.5;">
                                                                  <span style="color: rgb(127, 127, 127);">
                                                                    <span
                                                                      style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                      <span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                        <span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                          <span id="control"
                                                                            style="color: rgb(0, 0, 0);">
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                4. DO WE USE COOKIES AND OTHER TRACKING
                                                                                TECHNOLOGIES?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;">
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">
                                                                        <strong><em>In Short:</em></strong><em> We may
                                                                          use cookies
                                                                          and other tracking technologies to collect and
                                                                          store your information.</em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;">
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">We may use
                                                                        cookies
                                                                        and similar tracking technologies (like web
                                                                        beacons and pixels) to gather information when
                                                                        you interact with our Services. Some online
                                                                        tracking technologies help us maintain the
                                                                        security of our Services<bdt
                                                                          class="block-component"></bdt> and your
                                                                        account<bdt class="statement-end-if-in-editor">
                                                                        </bdt>, prevent crashes, fix bugs, save your
                                                                        preferences, and assist with basic site
                                                                        functions.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;">
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">We also permit
                                                                        third parties and service providers to use
                                                                        online tracking technologies on our Services for
                                                                        analytics and advertising, including to help
                                                                        manage and display advertisements, to tailor
                                                                        advertisements to your interests, or to send
                                                                        abandoned shopping cart reminders (depending on
                                                                        your communication preferences). The third
                                                                        parties and service providers use their
                                                                        technology to provide advertising about products
                                                                        and services tailored to your interests which
                                                                        may appear either on our Services or on other
                                                                        websites.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt class="block-component">
                                                                    <span style="font-size: 15px;"></span>
                                                                  </bdt>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;">
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">Specific
                                                                        information about how we use such technologies
                                                                        and how you can refuse certain cookies is set
                                                                        out in our Cookie Notice
                                                                        <span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt class="block-component"></bdt>.
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt class="block-component">
                                                                    <span style="font-size: 15px;"></span>
                                                                  </bdt>
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                          <span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                            <span style="font-size: 15px;">
                                                                              <span style="color: rgb(89, 89, 89);">
                                                                                <span style="font-size: 15px;">
                                                                                  <span style="color: rgb(89, 89, 89);">
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt
                                                                                        class="statement-end-if-in-editor">
                                                                                      </bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span style="font-size: 15px;">
                                                                      <bdt class="block-component"></bdt>
                                                                    </span>
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                            <span
                                                                              style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                              <span
                                                                                style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                                <span style="font-size: 15px;">
                                                                                  <span style="color: rgb(89, 89, 89);">
                                                                                    <span style="font-size: 15px;">
                                                                                      <span
                                                                                        style="color: rgb(89, 89, 89);">
                                                                                        <span
                                                                                          data-custom-class="body_text">
                                                                                          <bdt class="block-component">
                                                                                          </bdt>
                                                                                        </span>
                                                                                        <bdt class="block-component">
                                                                                          <span
                                                                                            data-custom-class="body_text">
                                                                                            <bdt
                                                                                              class="block-component">
                                                                                            </bdt>
                                                                                          </span>
                                                                                        </bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="inforetain" style="line-height: 1.5;">
                                                                  <span style="color: rgb(127, 127, 127);">
                                                                    <span
                                                                      style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                      <span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                        <span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                          <span id="control"
                                                                            style="color: rgb(0, 0, 0);">
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                5. HOW LONG DO WE KEEP YOUR INFORMATION?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;">
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">
                                                                        <strong><em> In Short: </em></strong><em>We keep
                                                                          your
                                                                          information for as long as necessary to <bdt
                                                                            class="block-component"></bdt>fulfil<bdt
                                                                            class="else-block"></bdt> the purposes
                                                                          outlined in this privacy notice unless
                                                                          otherwise required by
                                                                          law.</em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;">
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">
                                                                        We will only keep your personal information for
                                                                        as long as it is
                                                                        necessary for the purposes set out in this
                                                                        privacy notice, unless a longer retention period
                                                                        is required or permitted by law (such as tax,
                                                                        accounting, or other legal requirements).<bdt
                                                                          class="block-component"></bdt> No purpose in
                                                                        this notice will require us keeping your
                                                                        personal information for longer than
                                                                        <span style="font-size: 15px;">
                                                                          <span style="color: rgb(89, 89, 89);">
                                                                            <span data-custom-class="body_text">
                                                                              <bdt class="block-component"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt class="block-component"></bdt>the period of
                                                                        time in which users have an account with us<bdt
                                                                          class="block-component"></bdt>
                                                                        <span style="font-size: 15px;">
                                                                          <span style="color: rgb(89, 89, 89);">
                                                                            <span data-custom-class="body_text">
                                                                              <bdt class="else-block"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;">
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">
                                                                        When we have no ongoing legitimate business need
                                                                        to process your
                                                                        personal information, we will either delete or
                                                                        <bdt class="block-component"></bdt>anonymise<bdt
                                                                          class="else-block"></bdt> such information,
                                                                        or, if this is not possible (for example,
                                                                        because your personal information has been
                                                                        stored in backup archives), then we will
                                                                        securely store your personal information and
                                                                        isolate it from any further processing until
                                                                        deletion is possible.
                                                                        <span style="color: rgb(89, 89, 89);">
                                                                          <bdt class="block-component"></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="infosafe" style="line-height: 1.5;">
                                                                  <span style="color: rgb(127, 127, 127);">
                                                                    <span
                                                                      style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                      <span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                        <span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                          <span id="control"
                                                                            style="color: rgb(0, 0, 0);">
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;">
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">
                                                                        <strong><em> In Short: </em></strong><em>We aim
                                                                          to protect
                                                                          your personal information through a system of
                                                                          <bdt class="block-component"></bdt>
                                                                          organisational<bdt class="else-block"></bdt>
                                                                          and technical security measures.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;">
                                                                  <span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    <span
                                                                      style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                      <span data-custom-class="body_text">
                                                                        We have implemented appropriate and reasonable
                                                                        technical
                                                                        and <bdt class="block-component"></bdt>
                                                                        organisational<bdt class="else-block"></bdt>
                                                                        security measures designed to protect the
                                                                        security of any personal information we process.
                                                                        However, despite our safeguards and efforts to
                                                                        secure your information, no electronic
                                                                        transmission over the Internet or information
                                                                        storage technology can be guaranteed to be 100%
                                                                        secure, so we cannot promise or guarantee that
                                                                        hackers, cybercriminals, or other <bdt
                                                                          class="block-component"></bdt>unauthorised<bdt
                                                                          class="else-block"></bdt> third parties will
                                                                        not be able to defeat our security and
                                                                        improperly collect, access, steal, or modify
                                                                        your information. Although we will do our best
                                                                        to protect your personal information,
                                                                        transmission of personal information to and from
                                                                        our Services is at your own risk. You should
                                                                        only access the Services within a secure
                                                                        environment.
                                                                        <span style="color: rgb(89, 89, 89);">
                                                                          <bdt class="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                        <span
                                                                          style="color: rgb(89, 89, 89); font-size: 15px;">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt class="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div id="infominors" style="line-height: 1.5;">
                  <span style="color: rgb(127, 127, 127);">
                    <span style="color: rgb(89, 89, 89); font-size: 15px;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span id="control" style="color: rgb(0, 0, 0);">
                            <strong>
                              <span data-custom-class="heading_1">
                                7. DO WE COLLECT INFORMATION FROM MINORS?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <strong><em>In Short:</em></strong><em> We do not knowingly
                          collect data from or market to <bdt class="block-component"></bdt>children under 18 years of
                          age<bdt class="else-block"></bdt>.</em>
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we
                        knowingly sell such personal information. By using the Services, you represent that you are at
                        least 18 or that you are the parent or guardian of such a minor and consent to such minor
                        dependent’s use of the Services. If we learn that personal information from users less than 18
                        years of age has been collected, we will deactivate the account and take reasonable measures to
                        promptly delete such data from our records. If you become aware of any data we may have
                        collected from children under age 18, please contact us at 
                        <span style="color: rgb(89, 89, 89); font-size: 15px;">
                          <span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                            <bdt class="question">info@theblueion.com</bdt>
                            <bdt class="else-block"></bdt>
                          </span><span>.</span>
                        </span>
                        <span data-custom-class="body_text">
                          <bdt class="else-block">
                            <bdt class="block-component"></bdt>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div id="privacyrights" style="line-height: 1.5;">
                  <span style="color: rgb(127, 127, 127);">
                    <span style="color: rgb(89, 89, 89); font-size: 15px;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span id="control" style="color: rgb(0, 0, 0);">
                            <strong>
                              <span data-custom-class="heading_1">
                                8. WHAT ARE YOUR PRIVACY RIGHTS?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text"><strong><em>In Short:</em></strong><em> 
                        <span style="color: rgb(89, 89, 89);">
                          <span style="font-size: 15px;">
                            <span data-custom-class="body_text">
                              <em>
                                <bdt class="block-component"></bdt>
                              </em>
                            </span
                          ></span> 
                        </span>
                        You may review, change, or terminate your account at any
                        time, depending on your country, province, or state of residence.</em>
                        <span style="color: rgb(89, 89, 89);">
                          <span style="font-size: 15px;">
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div id="withdrawconsent" style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <strong><u>Withdrawing your consent:</u></strong> If we are
                        relying on your consent to process your personal information,<bdt class="block-component"></bdt>
                        which may be express and/or implied consent depending on the applicable law,<bdt
                          class="statement-end-if-in-editor"></bdt> you have the right to withdraw your consent at any
                        time. You can withdraw your consent at any time by contacting us by using the contact details
                        provided in the section <bdt class="block-component"></bdt>'<bdt class="else-block"></bdt>
                      </span>
                    </span>
                  </span>
                  <a data-custom-class="link" href="#contact">
                    <span style="font-size: 15px; color: rgb(0, 58, 250);">
                      <span style="font-size: 15px; color: rgb(0, 58, 250);">
                        <span data-custom-class="body_text">
                          HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </span>
                      </span>
                    </span>
                  </a>
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>'<bdt class="else-block"></bdt> below<bdt class="block-component"></bdt>.
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      However, please note that this will not affect the lawfulness of the
                      processing before its withdrawal nor,<bdt class="block-component"></bdt> when applicable law
                      allows,<bdt class="statement-end-if-in-editor"></bdt> will it affect the processing of your
                      personal information conducted in reliance on lawful processing grounds other than consent.<bdt class="block-component"></bdt>
                    </span>
                  </span>
                  <bdt class="block-component">
                    <span style="font-size: 15px;">
                      <span data-custom-class="body_text"></span>
                    </span>
                  </bdt>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="heading_2"><strong>Account Information</strong></span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span data-custom-class="body_text">
                    <span style="font-size: 15px;">
                      If you would at any time like to review or change the information in your account or terminate your
                      account, you can:<bdt class="forloop-component"></bdt>
                    </span>
                  </span>
                </div>
                <ul>
                  <li data-custom-class="body_text" style="line-height: 1.5;">
                    <span data-custom-class="body_text">
                      <span style="font-size: 15px;">
                        <bdt class="question">Contact us using the contact information provided.</bdt>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style="line-height: 1.5;">
                  <span data-custom-class="body_text">
                    <span style="font-size: 15px;">
                      <bdt class="forloop-component"></bdt>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      Upon your request to terminate your account, we will deactivate or delete your account and information
                      from our active databases. However, we may retain some information in our files to prevent fraud,
                      troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with
                      applicable legal requirements.
                    </span>
                  </span>
                  <bdt class="statement-end-if-in-editor">
                    <span style="font-size: 15px;">
                      <span data-custom-class="body_text"></span>
                    </span>
                  </bdt>
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <span style="font-size: 15px;">
                          <span style="color: rgb(89, 89, 89);">
                            <span style="font-size: 15px;">
                              <span style="color: rgb(89, 89, 89);">
                                <span data-custom-class="body_text">
                                  <span style="font-size: 15px;">
                                    <span style="color: rgb(89, 89, 89);">
                                      <bdt class="block-component"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt class="block-component">
                    <span style="font-size: 15px;">
                      <span data-custom-class="body_text"></span>
                    </span>
                  </bdt>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span data-custom-class="body_text">
                    <span style="font-size: 15px;">
                      If you have questions or comments about your privacy rights, you may email us at <bdt class="question">
                        info@theblueion.com</bdt>.
                    </span>
                  </span>
                  <bdt class="statement-end-if-in-editor">
                    <span style="font-size: 15px;">
                      <span data-custom-class="body_text"></span>
                    </span>
                  </bdt>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div id="DNT" style="line-height: 1.5;">
                  <span style="color: rgb(127, 127, 127);">
                    <span style="color: rgb(89, 89, 89); font-size: 15px;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span id="control" style="color: rgb(0, 0, 0);">
                            <strong>
                              <span data-custom-class="heading_1">
                                9. CONTROLS FOR DO-NOT-TRACK FEATURES
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (<bdt
                          class="block-component"></bdt>'DNT'<bdt class="else-block"></bdt>) feature or setting you can
                        activate to signal your privacy preference not to have data about your online browsing
                        activities monitored and collected. At this stage, no uniform technology standard for <bdt
                          class="block-component"></bdt>recognising<bdt class="else-block"></bdt> and implementing DNT
                        signals has been <bdt class="block-component"></bdt>finalised<bdt class="else-block"></bdt>. As
                        such, we do not currently respond to DNT browser signals or any other mechanism that
                        automatically communicates your choice not to be tracked online. If a standard for online
                        tracking is adopted that we must follow in the future, we will inform you about that practice in
                        a revised version of this privacy notice.
                      </span>
                    </span>
                  </span>
                  <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                  <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                  <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div id="policyupdates" style="line-height: 1.5;">
                  <span style="color: rgb(127, 127, 127);">
                    <span style="color: rgb(89, 89, 89); font-size: 15px;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span id="control" style="color: rgb(0, 0, 0);">
                            <strong>
                              <span data-custom-class="heading_1">
                                10. DO WE MAKE UPDATES TO THIS NOTICE?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <em><strong>In Short: </strong>Yes, we will update this notice as
                          necessary to stay compliant with relevant laws.</em>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated <bdt
                          class="block-component"></bdt>'Revised'<bdt class="else-block"></bdt> date at the top of this
                        privacy notice. If we make material changes to this privacy notice, we may notify you either by
                        prominently posting a notice of such changes or by directly sending you a notification. We
                        encourage you to review this privacy notice frequently to be informed of how we are protecting
                        your information.
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div id="contact" style="line-height: 1.5;">
                  <span style="color: rgb(127, 127, 127);">
                    <span style="color: rgb(89, 89, 89); font-size: 15px;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span id="control" style="color: rgb(0, 0, 0);">
                            <strong>
                              <span data-custom-class="heading_1">
                                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        If you have questions or comments about this notice, you may 
                        <span style="color: rgb(89, 89, 89); font-size: 15px;">
                          <span data-custom-class="body_text">
                            <bdt class="block-component">
                              <bdt class="block-component"></bdt>
                            </bdt>email us at <bdt class="question">info@theblueion.com or </bdt>
                            <bdt class="statement-end-if-in-editor">
                              <bdt class="block-component"></bdt>
                            </bdt>
                          </span>
                        </span>
                      </span>
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">contact us by post at:</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <span style="font-size: 15px;">
                          <span style="color: rgb(89, 89, 89);">
                            <span style="color: rgb(89, 89, 89);">
                              <span data-custom-class="body_text">
                                <bdt class="question">Blue Ion Limited</bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span style="color: rgb(89, 89, 89);">
                          <span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <bdt class="question">Doctors Park, 3rd Avenue</bdt>
                        <span style="color: rgb(89, 89, 89);">
                          <span style="font-size: 15px;">
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span data-custom-class="body_text" style="font-size: 15px;">
                    <bdt class="question">Parklands
                      <span data-custom-class="body_text">
                        <span style="color: rgb(89, 89, 89);">
                          <span style="font-size: 15px;">
                            <bdt class="statement-end-if-in-editor"></bdt>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;"> 
                    <span data-custom-class="body_text">
                      <bdt class="question">Nairobi</bdt>
                        <span style="color: rgb(89, 89, 89);">
                          <span style="font-size: 15px;">
                            <bdt class="block-component"></bdt>,
                            <bdt class="statement-end-if-in-editor"></bdt>
                            <bdt class="block-component"></bdt>
                            <bdt class="question"> P.O. Box 2908 - 00600</bdt>
                            <bdt class="statement-end-if-in-editor"></bdt>
                            <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <span style="font-size: 15px;">
                        <span data-custom-class="body_text">
                          <span style="color: rgb(89, 89, 89);">
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt class="question">Kenya</bdt>
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <span style="color: rgb(89, 89, 89);">
                        <bdt class="statement-end-if-in-editor">
                          <span style="font-size: 15px;">
                            <span data-custom-class="body_text">
                              <span style="color: rgb(89, 89, 89);">
                                <bdt class="statement-end-if-in-editor">
                                  <span style="font-size: 15px;">
                                    <span data-custom-class="body_text">
                                      <span style="color: rgb(89, 89, 89);">
                                        <bdt class="statement-end-if-in-editor"></bdt>
                                      </span>
                                    </span>
                                  </span>
                                </bdt>
                                <bdt class="statement-end-if-in-editor"></bdt>
                              </span>
                            </span>
                          </span>
                        </bdt>
                        <span data-custom-class="body_text">
                          <span style="color: rgb(89, 89, 89);">
                            <span style="font-size: 15px;">
                              <bdt class="statement-end-if-in-editor"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style="font-size: 15px;">
                      <span data-custom-class="body_text">
                        <span style="color: rgb(89, 89, 89);">
                          <bdt class="statement-end-if-in-editor">
                            <span style="color: rgb(89, 89, 89);">
                              <span style="font-size: 15px;">
                                <span data-custom-class="body_text">
                                  <bdt class="block-component">
                                    <bdt class="block-component"></bdt>
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </bdt>
                        </span>
                      </span>
                      <bdt class="block-component">
                        <span style="font-size: 15px;"></span>
                      </bdt>
                      <span style="font-size: 15px;">
                        <span data-custom-class="body_text">
                          <span style="color: rgb(89, 89, 89); font-size: 15px;">
                            <span style="font-size: 15px;">
                              <span data-custom-class="body_text">
                                <bdt class="statement-end-if-in-editor">
                                  <bdt class="block-component"></bdt>
                                </bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div id="request" style="line-height: 1.5;">
                  <span style="color: rgb(127, 127, 127);">
                    <span style="color: rgb(89, 89, 89); font-size: 15px;">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                          <span id="control" style="color: rgb(0, 0, 0);">
                            <strong>
                              <span data-custom-class="heading_1">
                                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>Based on the applicable laws of your country<bdt class="block-component"></bdt>, you may
                        <bdt class="block-component"> have the right to request access to the personal information we
                          collect from you, details about how we have processed it, correct inaccuracies, or delete
                          your personal information. You may also have the right to <bdt class="block-component">
                          </bdt>withdraw your consent to our processing of your personal information. These rights may
                          be limited in some circumstances by applicable law. To request to review, update, or delete
                          your personal information, please <bdt class="block-component"></bdt>
                          <span data-custom-class="body_text">
                            <span style="color: rgb(0, 58, 250);">
                              <bdt class="question">contact us</bdt>
                            </span>
                          </span>
                        </bdt>
                      </span>
                    </span>
                    <span data-custom-class="body_text">.</span>
                  </span>
                </div>
              </v-card-text>
            </div>

            <v-divider class="mt-2"></v-divider>

            <div class="d-flex justify-center mt-5">
              <span class="text-caption grey--text">
                {{ this.currentYear }} &copy; <a @click="goToHomePage" class="text-decoration-none">Bluezone</a>.
              </span>
            </div>
          </v-card>
        </v-col>
        <v-col end>
          <!-- only touch if you know what you're doing -->
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    showPassword: false,
    resend_otp: false,
    national_number: "KE",
    otp_verified: true,
    verification_otp: null,
    loading: false,
    toggle_exclusive: undefined,
    loading_dialog: true,
    pageReady: false,
    user: {
      username: " ",
    },
    phone: null,
    valid: true,
    remember: 0,
    timeout: 2000,
    otpRules: [
      (v) => !!v || "OTP is required",
      (v) => (v && v.length == 5) || "The OTP has 6 characters"
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Email or phone (+2547...) is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    currentYear: new Date().getFullYear(),
    config: {},
  }),
  name: "PrivacyPolicy",
  opts: {
    theme: {
      dark: false,
    },
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    computedLogo() {
      return this.config.logo_url;
    },
  },
  created() {
    localStorage.clear();
    this.systemConfig();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    gotToRegisterBasic() {
      this.$router.push("/register");
    },
    gotToRegisterFacilityManager() {
      this.$router.push("/register-facility-manager");
    },
    gotToRegisterOrganisation() {
      this.$router.push("/register-organisation");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    goToHomePage() {
      this.$router.push("/");
    },
    systemConfig() {
      setTimeout(() => {
        this.loading_dialog = false;
        this.pageReady = true;
      }, 1500);
      return;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>