import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#23a7dc',
          secondary: '#6dbc70',
          accent: '#c5e8f4',
          error: '#b71c1c',
          info: '#23a7dc',
          blue: '#23a7dc',
        },
      },
    },
});
