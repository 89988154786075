<template>
  <v-container fill-height fluid class="lighten-5 text-center ma-0 pa-0">
    <v-layout align-center justify-center>
      <!-- Loader  -->
      <v-row align="center" justify="center" v-if="loading_dialog">
        <v-col>
          <div class="text-center">
            <v-progress-circular indeterminate color="primary" :width="2" :size="25"></v-progress-circular>
          </div>
          <p caption class="mt-2">Loading... Please wait</p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" v-else>
        <v-col start>
          <!-- only touch if you know what you're doing -->
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12">
          <v-card class="mx-auto spacing-playground pa-2 align-center" outlined>
            <div class="d-flex justify-center mt-5" @click="goToHomePage">
              <v-img lazy-src="img/logo-128.png" max-height="178" max-width="178" src="img/logo-128.png"></v-img>
            </div>

            <div>
              <v-card-text class="text-left">
                <v-container class="container" style="margin-top: 3rem;" :class="isMobile ? 'mobile-style' : ''">
                  <v-form ref="form" v-model="valid" @submit.prevent="submitForm" class="px-5 py-3"
                    style="font-size: 16px;">
                    <v-alert v-if="showSuccessMessage && !isMobile" class="success-message" type="success" closable
                      dismissible @input="showSuccessMessage = false">
                      <h4> Message Sent!</h4>
                      <p style="font-size: 14px;">Thanks for completing the form.We'll be in touch soon!</p>
                    </v-alert>
                    <h1 class="pb-8">Contact Us</h1>
                    <v-row>
                      <v-col :cols="isMobile ? 12 : 6" class="custom-height">
                        <label>First Name <span>*</span></label>
                        <v-hover>
                          <v-text-field color="hsl(169, 82%, 27%)" base-color="hsl(170, 80%, 27%)" class="custom-inputs"
                            v-model="firstName" style="margin-top: 8px;" variant="outlined" density="comfortable"
                            required></v-text-field>
                        </v-hover>
                        <div
                          style="height: 5px; margin-top: -12px; margin-bottom: 10px; font-size: 14px; color:hsl(0, 67%, 59%);"
                          v-if="firstNameErrors.length !== 0">
                          This
                          field is
                          required</div>
                      </v-col>
                      <v-col :cols="isMobile ? 12 : 6">
                        <label>Last Name <span>*</span></label>
                        <v-hover>
                          <v-text-field color="hsl(169, 82%, 27%)" base-color="hsl(170, 80%, 27%)" v-model="lastName"
                            style="margin-top: 8px;" variant="outlined" density="comfortable" required></v-text-field>
                        </v-hover>
                        <div
                          style="height: 5px; margin-top: -12px; margin-bottom: 10px; font-size: 14px; color:hsl(0, 67%, 59%);"
                          v-if="lastNameErrors.length !== 0">
                          This
                          field is
                          required</div>
                      </v-col>
                    </v-row>
                    <v-row style="margin-top: -20px;">
                      <v-col cols="12">
                        <label>Email Address <span>*</span></label>
                        <v-text-field color="hsl(169, 82%, 27%)" base-color="hsl(170, 80%, 27%)" class="custom-inputs"
                          v-model="email" style="margin-top: 8px;" variant="outlined" density="comfortable"
                          required></v-text-field>
                        <div style=" margin-top: -8px;margin-bottom: 10px ; font-size: 14px; color:hsl(0, 67%, 59%);"
                          v-if="emailErrors.includes('Email is required')">
                          This
                          field is
                          required</div>
                        <div style=" margin-top: -8px;margin-bottom: 10px ; font-size: 14px; color:hsl(0, 67%, 59%);"
                          v-else-if="emailErrors.includes('Email must be valid')">Email must be valid</div>
                      </v-col>
                    </v-row>
                    <label>Query Type <span>*</span></label>
                    <v-row style=" margin-top: 1px;">
                      <v-radio-group v-model="selectedRadio" inline required>
                        <v-col :cols="isMobile ? 12 : 6">
                          <div class="radio-button first-radio"
                            :class="{ 'selected-radio': selectedRadio === 'first' }">
                            <v-radio class="radio" :class="{ 'text-color': selectedRadio === 'first' }" value="first"
                              label="General Enquiry" color="teal-darken-2"></v-radio>
                          </div>
                        </v-col>
                        <v-col :cols="isMobile ? 12 : 6">
                          <div class="radio-button second-radio"
                            :class="{ 'selected-radio': selectedRadio === 'second' }">
                            <v-radio class="radio" :class="{ 'text-color': selectedRadio === 'second' }" value="second"
                              label="Support Request" color="teal-darken-2"></v-radio>
                          </div>
                        </v-col>
                      </v-radio-group>
                    </v-row>
                    <div style=" margin-top: -20px;margin-bottom: 20px ; font-size: 14px; color:hsl(0, 67%, 59%);"
                      v-if="radioErrors.length !== 0">
                      Please select a query type</div>
                    <v-row style="margin-top: -20px;">
                      <v-col cols="12">
                        <label>Message <span>*</span></label>
                        <v-textarea color="hsl(169, 82%, 27%)" base-color="hsl(170, 80%, 27%)" class="custom-inputs"
                          v-model="message" auto-grow variant="outlined"></v-textarea>
                      </v-col>
                    </v-row>
                    <div
                      style="height: 5px; margin-top: -22px; margin-bottom: 22px; font-size: 14px; color:hsl(0, 67%, 59%);"
                      v-if="messageErrors.length !== 0">
                      This
                      field is
                      required</div>
                    <v-row style="margin-top: -16px; margin-left: -20px;" align="center">
                      <v-col cols="1">
                        <v-checkbox v-model="consent" required></v-checkbox>
                      </v-col>
                      <v-col cols="11">
                        <p style="margin-bottom: 20px;"
                          :style="[isMobile ? { 'margin-left': '20px' } : { 'margin-left': '0px' }]">I consent to being
                          contacted by the team
                          <span>*</span>
                        </p>
                      </v-col>

                    </v-row>
                    <div
                      style="height: 5px; margin-top: -40px; margin-bottom: 40px; font-size: 14px; color:hsl(0, 67%, 59%);"
                      v-if="consentErrors.length !== 0">
                      To submit this form, please consent to being contacted</div>
                    <v-alert v-if="showSuccessMessage && isMobile" type="success" closable dismissible
                      @input="showSuccessMessage = false">
                      <h4> Message Sent!</h4>
                      <p style="font-size: 14px;">Thanks for completing the form.We'll be in touch soon!</p>
                    </v-alert>
                    <v-btn @click="validateForm" block height="52">
                      Submit
                    </v-btn>



                  </v-form>
                </v-container>
              </v-card-text>
            </div>

            <v-divider class="mt-2"></v-divider>

            <div class="d-flex justify-center mt-5">
              <span class="text-caption grey--text">
                {{ this.currentYear }} &copy; <a @click="goToHomePage" class="text-decoration-none">Bluezone</a>.
              </span>
            </div>
          </v-card>
        </v-col>
        <v-col end>
          <!-- only touch if you know what you're doing -->
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    showPassword: false,
    resend_otp: false,
    national_number: "KE",
    otp_verified: true,
    verification_otp: null,
    loading: false,
    toggle_exclusive: undefined,
    loading_dialog: true,
    pageReady: false,
    user: {
      username: " ",
    },
    phone: null,
    remember: 0,
    timeout: 2000,
    otpRules: [
      (v) => !!v || "OTP is required",
      (v) => (v && v.length == 5) || "The OTP has 6 characters"
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Email or phone (+2547...) is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    currentYear: new Date().getFullYear(),
    config: {},
    form: null,
    valid: false,
    selectedRadio: null,
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    consent: false,
    showSuccessMessage: false, 
    // error messages
    firstNameErrors: [],
    lastNameErrors: [],
    emailErrors: [],
    radioErrors: [],
    messageErrors: [],
    consentErrors: [],
    isMobile: window.innerWidth < 600,
  }),
  name: "PrivacyPolicy",
  opts: {
    theme: {
      dark: false,
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    computedLogo() {
      return this.config.logo_url;
    },
  },
  created() {
    localStorage.clear();
    this.systemConfig();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize());
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize())
  },
  methods: {
    validateForm() {
      this.firstNameErrors = [];
      this.lastNameErrors = [];
      this.emailErrors = [];
      this.radioErrors = [];
      this.messageErrors = [];
      this.consentErrors = [];

      if (!this.firstName) {
        this.firstNameErrors.push('First name is required');
      }

      if (!this.lastName) {
        this.lastNameErrors.push('Last name is required');
      }

      if (!this.email) {
        this.emailErrors.push('Email is required')
      } else if (!/.+@.+\..+/.test(this.email)) {
        this.emailErrors.push('Email must be valid')
      }

      if (this.selectedRadio === null) {
        this.radioErrors.push('Please select a radio');
      }

      if (!this.message) {
        this.messageErrors.push('Please write a message');
      }

      if (!this.consent) {
        this.consentErrors.push('Please consent to this');
      }

      if (!this.firstNameErrors.length && !this.lastNameErrors.length && !this.emailErrors.length) {
        this.submitForm();
      }
    },
    submitForm() {
      let payload = { 
        firstName: this.firstName, 
        lastName: this.lastName, 
        email: this.email 
      };

      console.log(payload);
      this.showSuccessMessage = true;
    },
    handleResize() {
      this.isMobile = window.innerWidth < 600;
    },
    validate() {
      this.$refs.form.validate();
    },
    gotToRegisterBasic() {
      this.$router.push("/register");
    },
    gotToRegisterFacilityManager() {
      this.$router.push("/register-facility-manager");
    },
    gotToRegisterOrganisation() {
      this.$router.push("/register-organisation");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    goToHomePage() {
      this.$router.push("/");
    },
    systemConfig() {
      setTimeout(() => {
        this.loading_dialog = false;
        this.pageReady = true;
      }, 1500);
      return;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.container {
  max-width: 700px;
  background-color: white;
  border-radius: 20px
}

.radio-button {
  border: 1px solid hsl(146, 26%, 60%);
  border-radius: 5px;
  height: 50px;
}

.radio {
  padding-top: 4px;
}

.selected-radio {
  background-color: hsl(148, 38%, 91%);
}

span {
  color: hsl(169, 82%, 27%);
}

button {
  background-color: hsl(169, 82%, 27%);
  color: white;
}

.v-checkbox {
  color: hsl(169, 82%, 27%);
}

.mobile-style {
  max-width: 90%;
  margin-bottom: 2rem;
}

.success-message {
  position: absolute;
  top: 0.5rem;
  left: 720px;
}
</style>